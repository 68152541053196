import React, { useState,useEffect } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

import YetiCard from '../../components/Modals/YetiCard'
import MintYeti from '../../components/Yeti/MintYeti'
import { Tab, Nav } from 'react-bootstrap';

import {loadMyNftsData,loadAllNftsData} from "../../services/actions"

const Gallery = (props) => {
  const {user,nftInfo,myNfts,allNfts} = props;
  const [showYetiCard, setShowYetiCard] = useState(false);
  const [currentTab, setCurrentTab] = useState('your_yetis');

  const [myFrom, setMyFrom] = useState(0);
  const [allFrom, setAllFrom] = useState(0);


  useEffect(()=>{
    loadNfts(currentTab);
  },[]);

  const changeTab=(val)=>{
    setCurrentTab(val);
    loadNfts(val);
  }

  const loadNfts =(tab_name)=>{
    if(tab_name == 'your_yetis'){
      if(myNfts.type=="" || myNfts.type=="success"){
        props.loadMyNftsData(user.data.eth_address,myFrom);
        setMyFrom(myFrom+1)
      }
    }else{
      if(allNfts.type=="" || allNfts.type=="success"){
        props.loadAllNftsData(allFrom);
        setAllFrom(allFrom+1);
      }
    }
  }

  return (
    <>
      {showYetiCard ?
        <>
          <YetiCard show={showYetiCard!=false} nft={showYetiCard} handleCloseAlert={() => { setShowYetiCard(false); }} />
        </>
        : ""}


      <section className="mint_section" style={{ height: '100%' }}>
        <div className="container content-space-2">
          <div className="row justify-content-center pt-5">

            <div className="col-lg-5 d-flex align-items-center">
              <div className="content">
                <h3 className="content_upper_title d-none d-md-block"> <i className="fas fa-skiing"></i> {nftInfo.maxSupply} Yetis</h3>
                <h2 className="font-72">Yeti Gallery</h2>
                <p className=" content_text" style={{ paddingRight: "3rem" }}>Each Yeti is unique and programmatically generated from over 50 possible traits, including expression, headwear, clothing, and more. </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 d-none d-md-block">
            <div className="gallery_mobile_image">
              <div className="row">
                <div className="col-6">
                  <div className="card border-0">
                    <img className="img-responsive" src="./assets//gallery1.svg" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="card border-0">
                    <img className="img-responsive" src="./assets//gallery2.svg"/>
                  </div>
                </div>
              </div>
            </div>
            </div>

          </div>
        </div>
      </section>


      <section className="py-5">
        <div className="container">
          <Tab.Container id="left-tabs-example" activeKey={currentTab} onSelect={changeTab}>
            <div className="row">
              <div className="col">

                <Nav variant="pills" className="yetis_tab">
                  <Nav.Item>
                    <Nav.Link eventKey="all_yetis">All Yetis ({nftInfo.totalSupply})</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="your_yetis">Your Yetis ({nftInfo.myNftsCount})</Nav.Link>
                  </Nav.Item>
                </Nav>


              </div>
            </div>

            <div className="row justify-content-md-center pt-4">
              <div className="col-lg-6 col-md-12 ">
                <div className="stack_area px-4 py-3 d-flex w-100">
                  <h6 className="stack_text pt-3">Stake your Yetis at PolyientX and start earning $DYSC</h6>
                  <a href="https://app.polyientx.com/" className="btn stack_btn py-3" target="_blank">Stake Now</a>
                </div>
              </div>
            </div>
            <div>
              <Tab.Content>
                <Tab.Pane eventKey="all_yetis">
                <div className="row py-3">
                    <MintYeti nfts={allNfts.data} viewNft={(v)=>setShowYetiCard(v)}/>
                    {allNfts.type=='new_request'&&
                    <div className="d-flex justify-content-center align-self-center">
                      <h3  className="pt-5"> Loading...</h3>
                      <br/>
                      <br/>
                     </div>
                    }
                  {((allNfts.type=='success' || allNfts.type=='request') && allNfts.data.length < nftInfo.totalSupply) &&
                      <div className="d-flex justify-content-center align-self-center pt-5">
                          <button onClick={()=>loadNfts(currentTab)} className="btn btn-primary mint_btn align-items-center" disabled={allNfts.type=='request'}> {allNfts.type=='request' ? "Loading..." : "Load More"}</button>
                      </div>
                    }
                </div>
                </Tab.Pane>
                <Tab.Pane eventKey="your_yetis">
                  <div className="row py-3">
                    <MintYeti nfts={myNfts.data} viewNft={(v)=>setShowYetiCard(v)}/>
                    {myNfts.type=='new_request'&&
                    <div className="d-flex justify-content-center align-self-center">
                      <h3  className="pt-5"> Loading...</h3>
                      <br/>
                      <br/>
                     </div>
                    }
                    {(myNfts.type=='success' && myNfts.data.length == 0)&&
                    <div className="d-flex justify-content-center align-self-center">
                      <h3  className="pt-5">No Yetis? No worries. <Link to="/" className="btn btn-primary mint_btn align-items-center py-2" style={{height:"45px"}}>  Mint now!</Link></h3>
                      <br/>
                      <br/>
                     </div>
                    }

                  {((myNfts.type=='success' || myNfts.type=='request') && myNfts.data.length < nftInfo.myNftsCount) &&
                      <div className="d-flex justify-content-center align-self-center pt-5">
                          <button onClick={()=>loadNfts(currentTab)} className="btn btn-primary mint_btn align-items-center" disabled={myNfts.type=='request'}> {myNfts.type=='request' ? "Loading..." : "Load More"}</button>
                      </div>
                    }


                  </div>
                </Tab.Pane>
              </Tab.Content>

            </div>

          </Tab.Container>
        </div>
      </section>



    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  nftInfo: state.nftInfo,
  myNfts: state.myNfts,
  allNfts: state.allNfts,
})

const mapDispatchToProps = {
  loadMyNftsData,loadAllNftsData
}
export default connect(mapStateToProps, mapDispatchToProps)(Gallery)

