import React, { useState } from 'react'
import { Link,withRouter } from 'react-router-dom'

import { connect } from "react-redux";

import {userLogin} from "../../services/actions"
import {shortEthAddress} from "../../utils/ethereum"

const Header = (props) => {
  const {user} = props;
  const [nav,showNav] = useState(false);
  let pathName = props.location.pathname;
   
  return (
    <>
      <header id="header" className="navbar navbar-expand-lg navbar-light navbar-right bg-white border-bottom">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-white  w-100">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/"> <img src="./assets/header_logo.svg" className="" />
              </Link >
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation" onClick={()=>{showNav(!nav)}}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={nav ?"collapse navbar-collapse show" :"collapse navbar-collapse"} id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link px-4 py-2" aria-current="page" href="https://www.yetiski.club/" target="_blank">About</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link px-4 py-2" href="https://discord.com/invite/vbghzyXmcy" target="_blank">Discord</a>
                  </li>
                  <li className="nav-item">
                    <Link className={pathName=="/gallery" ? "nav-link active px-4 py-2":"nav-link px-4 py-2"} to="/gallery">
                      Gallery
                    </Link>

                  </li>
                  <li className="nav-item">
                    <Link className={(pathName=="/" || pathName=="/mint") ? "nav-link active px-4 py-2":"nav-link px-4 py-2"} to="/">
                      Mint Yetis
                    </Link>

                  </li>
                  {
                    user.login
                      ?
                      <>
                        <li className="nav-item">
                          <a className="nav-link px-4 py-0" href="#">
                           {shortEthAddress(user.data.eth_address)} <img src="./assets//profile.svg" width="45" />
                          </a>

                        </li>
                      </>
                      :
                      <>
                       
                      </>
                  }
                </ul>

              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}



const mapStateToProps = (state) => ({
  user: state.user,
  nftInfo: state.nftInfo,
})
const mapDispatchToProps = {
  userLogin
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));