import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from "react-redux";

import { userLogin,mintYetisWithEth,mintYetisWithToken,resetMint } from "../../services/actions"

import * as constants from "../../services/constants"

export const Home = (props) => {
  const [mintProcess, setMintProcess] = useState(false);
  const [mintNumber, setMintNumber] = useState(1);

  const [error, setError] = useState("");
  const { user,nftInfo } = props;

  const price = nftInfo.price/(10**18);

  useEffect(() => {
    props.resetMint();
  }, [])

  const setMintCount = (e) => {
    let num = e.target.value;
    if (num > 0) {
      setError("");
      setMintNumber(num);
    }
  }

  const mintTokenBtn = (type)=>{
    setError("");
    setMintProcess(true)
    if(type == 'eth'){
      props.mintYetisWithEth(mintNumber,user.data.eth_address,(data)=>{
        setMintProcess(false)
        if(data.type=="success"){
          props.history.push({
            pathname: '/mint',
            search: '',
            params: { mintData: data }
          })
        }else{
          setError(data.message);
        }
      })
    }else{
      props.mintYetisWithToken(constants.DYSC_ADDRESS,mintNumber,user.data.eth_address,(data)=>{
        setMintProcess(false)
        if(data.type=="success"){
          props.history.push({
            pathname: '/mint',
            search: '',
            params: { mintData: data }
          })
        }else{
          setError(data.message);
        }
      })
    }
  
  }

  return (
    <>
      <section className="main-section">
        <div className="container content-space-2">
          <div className="row">
            <div className="col-lg-5 text-center">
              <img src="./assets//logo.svg" className=" yeti_img" width="350" />
            </div>
            <div className="col-lg-6">
              <div className="content ">
                {user.login &&
                  <h3 className="content_upper_title"> <i className="fas fa-skiing"></i> {nftInfo.leftSupply} Yeti’s Left</h3>
                }
                <h2 className="content_title">Join The Club—Mint A Yeti</h2>
                <p className="  content_text">You can join The Degen Yeti Ski Club (DYSC) by collecting one of the 8,888 Yeti NFTs living on the Avalanche blockchain. Each Yeti is a unique, digital collectible, selling for 4 AVAX with no price tiers and no frills.</p>

                <div className="row mint_form">

                  {user.login ?
                    <>
                      <h3 className="content_sub_title pb-2"><img src="./assets//img1.png" width="30" className=" me-3" /> {price} AVAX PER YETI</h3>
                      <div className={nftInfo.myTokenBalance>0 ? "col-md-3 pl-0" : "col-md-6 pl-0"}>
                        <input className={mintNumber > 20 ? "form-control mint_box err_box" : "form-control mint_box"} type="number" value={mintNumber} placeholder="Number of Yetis (max 20)" min="1" max="20" onChange={(e) => setMintCount(e)} disabled={mintProcess} />
                      </div>
                      <div className={nftInfo.myTokenBalance>0 ? "col-md-9" : "col-md-6"}>
                        {false ?
                          <div className="row">
                             <div className="col-md-6">
                               <button className="btn btn-primary mint_btn w-100 align-items-center" onClick={()=>{mintTokenBtn('eth')}} disabled={mintNumber > 20 || mintProcess}>{mintProcess ? "Minting..." : <>AVAX Mint <i className="far fa-arrow-right"></i></>}</button>
                             </div>
                             {!mintProcess &&
                             <div className="col-md-6">
                             <button className="btn btn-primary mint_btn w-100 align-items-center" onClick={()=>{mintTokenBtn('dysc')}} disabled={mintNumber > 20 || mintProcess}>{mintProcess ? "Minting..." : <>DYSC Mint <i className="far fa-arrow-right"></i></>}</button>
                             </div>
                             }
                          </div>
                             :
                        <button className="btn btn-primary mint_btn w-100 align-items-center" onClick={()=>{mintTokenBtn('eth')}} disabled={mintNumber > 20 || mintProcess}>{mintProcess ? "Minting..." : <>Mint Yetis <i
                          className="far fa-arrow-right"></i></>}</button>
                        }

                      </div>
                      {mintNumber > 20 &&
                        <p className="err_msg ms-3 pt-3">You can mint a maximum of 20 Yetis at a time.</p>
                      }
                      {error != "" &&
                        <p className="err_msg ms-3 pt-3">{error}</p>
                      }
                      
                    </>
                    :
                    <>
                     <div className="d-flex justify-content-center">
                        <button className="mint_btn  mx-auto col-lg-9 col-md-8 col-sm-12" onClick={() => { props.userLogin() }}><img src="./assets//metamask-fox-logo.svg" width="" /> Connect With Metamask</button>
                       
                      </div>
                      {user.message != "" &&
                          <p className="err_msg ms-3 pt-3 text-center">{user.message}</p>
                        }
                    </>
                  }



                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

Home.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.user,
  nftInfo: state.nftInfo,
  mintInfo: state.mintInfo,
})

const mapDispatchToProps = {
  userLogin,mintYetisWithEth,mintYetisWithToken,resetMint
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
