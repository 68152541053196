import {ETH_ENV} from '../constants'

const chainData = {
  "production": [
    {
      "name": "Avalanche Mainnet",
      "short_name": "Avalanche",
      "key": "avalanche_prod",
      "chainId": 43114,
      "shortName": "Avalanche",
      "chain": "AVAX",
      "network": "mainnet",
      "networkId": 1,
      "nativeCurrency": {
        "name": "Avalanche",
        "symbol": "AVAX",
        "decimals": 18
      },
      "rpc": [
        "https://api.avax.network/ext/bc/C/rpc"
      ],
      "faucets": [],
      "explorers": [{"url": "https://avascan.info/blockchain/c",}],
      "infoURL": "https://cchain.explorer.avax.network/"
    },
  ],
  "development": [
    {
      "name": "Avalanche Fuji Testnet",
      "short_name": "Avalanche",
      "key": "avalanche_dev",
      "chainId": 43113,
      "shortName": "Fuji",
      "chain": "AVAX",
      "network": "testnet",
      "networkId": 1,
      "nativeCurrency": {
        "name": "Avalanche",
        "symbol": "AVAX",
        "decimals": 18
      },
      "rpc": [
        "https://api.avax-test.network/ext/bc/C/rpc"
      ],
      "faucets": [
        "https://faucet.avax-test.network/"
      ],
      "explorers": [
        {
          "url": "https://cchain.explorer.avax-test.network/"
        }
      ],
      "infoURL": "https://cchain.explorer.avax-test.network"
    },
  ]
}
let currentChain;
if(ETH_ENV == 'production'){
  currentChain = chainData['production'];
}else{
  currentChain =  chainData['development'];
}

export const isChainAllow = (chainId)=>{
  let chain = currentChain.find((v)=>v.chainId == chainId);
   if(chain){
     return true;
   }else{
     return false;
   }
}

export const getNetwork = (chainId)=>{
  let chain = currentChain.find((v)=>v.chainId == chainId);
   if(chain){
     return chain;
   }else{
     return false;
   }
}

export default currentChain;