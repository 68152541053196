import React from 'react'
import Modal from 'react-bootstrap/Modal'

import * as constants from "../../services/constants"

const YetiCard = (props) => {
  const {nft}=props;

  let data = [
    {
      attr_type: "Eyes",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Eyes").value
    },
    {
      attr_type: "Right hand",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Right Hand").value
    },
    {
      attr_type: "Left Hand",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Left Hand").value
    },
    {
      attr_type: "Torso",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Torso").value
    },
    {
      attr_type: "Hat",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Hat").value
    },
    {
      attr_type: "Expressions",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Expressions").value
    },
    {
      attr_type: "Bonus",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Face").value
    },
  ]

  let property_data = data.map((key, index) => {
    return (
      <div className="row" key={key.attr_type}>
        <div className="col-lg-4"><h6 className="attr_type">{key.attr_type} </h6></div>
        <div className="col-lg-8"><h6 className="attr_value">{key.attr_value} </h6></div>
      </div>
    )
  })

  const shareTwitter = ()=>{
    window.open("https://twitter.com/share?text=I just joined the Degen Yeti Ski Club (DYSC), check out these rare Yetis!&url="+constants.DOMAIN_NAME+'nft/'+nft.id+"&hashtags=Yetis,Avalanche");
  }
  
  // let property_data = nft.attributes.map((attr, index) => {
  //   return (
  //     <>
  //       <div className="col-lg-4"><h6 className="attr_type">{attr.trait_type} </h6></div>
  //       <div className="col-lg-8"><h6 className="attr_value">{attr.value} </h6></div>
  //     </>
  //   )
  // })
  return (
    <>
      <Modal show={props.show} onHide={props.handleCloseAlert} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-alert">

        <Modal.Body>
          <div className="card  mx-auto border-0">
            <img src={nft.image} className="img-fluid"/>
            <div className="card-body pt-4 px-2 ">
              <div className=" text-center border-bottom">
                <h5 className="card-title yeti_token" style={{ fontSize: "22px" }}>DYSC | Yeti #{nft.id}</h5>
                <h6 className="font-16 yeti_token pb-3">{nft.attributes.find((v)=>v.trait_type=="Rarity").value}</h6>
              </div>

              <div className="row py-3">

                {property_data}

                <br/>
                <br/>
                <div className="d-flex justify-content-center align-self-center">
                 <button
                      className="btn btn-light bg-transparent  w-100 align-items-center share_btn  px-3 py-2 rounded-0 border  border-secondary" style={{height:"51.31px"}} onClick={shareTwitter}>Share
                      On Twitter <i className="far fa-arrow-right"></i></button>
                </div>
              </div>
              <hr />
              <button className="btn btn-light w-100 rounded-0 border-0 bg-transparent trait_font font-16" style={{
                textDecorationLine: " underline",
                textTransform: "capitalize"
              }}
              onClick={()=>{props.handleCloseAlert()}}
              >close</button>
            </div>
          </div>

        </Modal.Body>

      </Modal>

    </>
  )
}

export default YetiCard
